'use client'

import { useState, createContext, useContext } from 'react'
import Link from 'next/link'
import { Message } from '@locmod/intl'
import cookieStorage from 'cookie-storage'

import { Icon } from 'components/ui'

import messages from './messages'


export const TopNoticeContext = createContext(false)
export const useTopNoticeVisibility = () => useContext(TopNoticeContext)

export const topNoticeCookieName = 'topNoteClosed'
export const topNoticeVisibleBodyClassName = 'top-notice-visible'
export const topNoticeDefaultHeight = '4rem'

const TopNoticeProvider: React.CFC<{ initialState: boolean }> = ({ children, initialState }) => {
  const [ isVisible, setVisibility ] = useState(initialState)

  const handleCloseClick = () => {
    setVisibility(false)
    cookieStorage.setSessionItem(topNoticeCookieName, 'true')
    document.body.classList.remove(topNoticeVisibleBodyClassName)
    document.body.style.setProperty('--top-notice-h', '0px')
  }

  return (
    <TopNoticeContext.Provider value={isVisible}>
      {
        isVisible && (
          <div className="fixed top-0 left-0 z-50 w-full px-4 sm:px-12 flex items-center justify-center text-white bg-grey-90" style={{ minHeight: topNoticeDefaultHeight }}>
            <Message className="py-1.5 px-2 rounded-2 text-land-body-3 bg-brand-50 uppercase" value={messages.important} />
            <Message className="ml-4 sm:ml-6 text-land-body-3 -sm:pr-6" value={{ ...messages.text, components: { Link } }} html />
            <button
              className="absolute top-1/2 -translate-y-1/2 right-4 sm:size-8 -sm:size-4 p-1 text-grey-60 hocus:text-white"
              aria-label="Hide notice"
              onClick={handleCloseClick}
            >
              <Icon className="size-full" name="interface/close" />
            </button>
          </div>
        )
      }
      {children}
    </TopNoticeContext.Provider>
  )
}

export default TopNoticeProvider
