import { constants } from 'helpers'


export default {
  important: {
    en: 'Important',
  },
  text: {
    en: `AZUR is here! Read about it <a href="${constants.links.azur}" alt="" class="underline" target="_blank">in our Docs</a>, and learn more <a href="${constants.links.stakingDoc}" alt="" class="underline" target="_blank">about Staking</a>`,
  },
}
