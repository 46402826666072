'use client'

import { useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
// eslint-disable-next-line import/newline-after-import
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

import { IntlProvider } from '@locmod/intl'
import { SvgProvider, SvgSprite } from 'svg-provider'
import { DeviceProvider } from 'contexts'


type Props = {
  userAgent: string
}

const alwaysFocusableTags = [
  'INPUT',
  'TEXTAREA',
]

const BaseProviders: React.CFC<Props> = (props) => {
  const { children, userAgent } = props

  useEffect(() => {
    const className = 'focus-visible'

    const mouseHandler = () => {
      const focusedElement = document.activeElement
      const hasClassName = document.documentElement.classList.contains(className)

      if (focusedElement && !hasClassName && alwaysFocusableTags.includes(focusedElement.tagName)) {
        document.documentElement.classList.add(className)
      }
      else if (hasClassName) {
        document.documentElement.classList.remove(className)
      }
    }

    const keyboardEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Tab' && !document.documentElement.classList.contains(className)) {
        document.documentElement.classList.add(className)
      }
    }

    document.addEventListener('mousedown', mouseHandler)
    document.addEventListener('keydown', keyboardEventHandler)
  }, [])

  return (
    <DeviceProvider userAgent={userAgent}>
      <SvgProvider>
        <IntlProvider locale="en">
          {children}
        </IntlProvider>
        <div className="sr-only">
          <SvgSprite />
        </div>
      </SvgProvider>
    </DeviceProvider>
  )
}

export default BaseProviders
